<template>
  <NotInWx v-if="!isInWeChat" />
</template>
<script>
import Vue from "vue";
import { ref, defineComponent } from "@vue/composition-api";
import { getQueryVariable, isWeChat } from "@/utils/utils";
import { xetClientGetCorpid, xetClientLogin } from "@/api/polyv";
import { autoProtocol } from "@/utils/utils";
import { getSignData } from "@/api/common";
import NotInWx from "@/views/components/NotInWx.vue";

import useAuthcheck from "@/hooks/useAuthcheck";

export default defineComponent({
  components: {
    NotInWx
  },
  setup() {
    const isInWeChat = ref(isWeChat());

    function getChannelId() {
      try {
        const url = decodeURIComponent(getQueryVariable("redirect_uri"))
        const urlIns = new URL(url);
        return urlIns.searchParams.get("alive_id") || urlIns.pathname?.split("/").pop();
      } catch {
        return "";
      }
    }
    const channel_id = getChannelId();
    let corp_id = "";
    function logIn() {
      if (!channel_id) {
        return;
      }
      xetClientGetCorpid({ channel_id })
        .then(res => {
          if (res.code == 200) {
            corp_id = res.data.corp_id;
            return;
          }
          throw new Error("fail to get corp_id");
        })
        .then(() => useAuthcheck(corp_id))
        .then(res => {
          if (res !== "uncheck") {
            return getSignData(corp_id);
          }
          throw new Error("fail to check auth");
        })
        .then(res => {
          Vue.prototype.app_id = res.app_id;
        })
        .then(() =>
          xetClientLogin({
            channel_id,
            corp_id,
          })
        )
        .then(res => {
          console.log(corp_id, channel_id);
          if (res.code === 200) {
            window.location = autoProtocol(res.data.url);
          }
        });
    }

    if (isInWeChat.value) {
      logIn();
    }

    return {
      isInWeChat
    };
  }
});
</script>
